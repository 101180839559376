

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; 
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const handlePasswordToggle = () => setShowPassword(!showPassword);

  // const handleLogin = async (e) => {
  //   e.preventDefault();

  //   const storedUserData = JSON.parse(localStorage.getItem('userData'));

  //   if (storedUserData) {
  //     const { email: storedEmail, password: storedPassword } = storedUserData;

  //     if (email === storedEmail && password === storedPassword) {
  //       if (rememberMe) {
  //         localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
  //       }
  //      toast.success("Login successful!");
  //       navigate('/chatpanel'); // Navigate to home page after successful login
  //     } else {
  //       toast.error("Invalid credentials. Please try again.");
  //     }
  //   } else {
  //     toast.error("User not found. Please sign up first.");
  //   }
  // };

  const handleLogin = async (e) => {
    e.preventDefault();

    // const { email, password } = formData; // Get the email and password from the form data

    if (!email || !password) {
        toast.error("Please enter email and password.");
        return;
    }

    try {
        const response = await fetch(' https://demochatapi.emedha.in/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        });
        localStorage.setItem('loginEmail',email)
        const data = await response.json();

        if (response.status === 200) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('userData', JSON.stringify({ email, password }));  
            if (rememberMe) {
                localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
            }

            toast.success("Login successful!");
            navigate('/chatpanel'); // Navigate to home page after successful login
          } else if (response.status === 500) {
            // Internal Server Error
            setError("Server error occurred. Please try again later.");
            console.error("Internal Server Error");
            
          } else if (response.status === 503) {
            // Service Unavailable
            setError("Service is currently unavailable. Please try again later.");
            console.error("Service Unavailable");
            
          } else {
            // Handle other statuses (e.g., 400 Bad Request, 401 Unauthorized)
            const data = await response.json();
            setError(data.error || "Login failed. Please try again.");
          }
        } catch (error) {
          console.error("Error logging in:", error);
          alert('Login failed. Please try again later.');
          setError("Login failed. Please try again.");
        }
      };

  return (
    <div className="auth-container">
      <div className="auth-card ">
        <div className="auth-header">
          <h3>Welcome Back</h3>
          <p className="subheading">Please login to continue</p>
        </div>
        <div className="auth-body">
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="inputEmail">Email Address</label>
              <input
                className="form-control"
                id="inputEmail"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputPassword">Password</label>
              <input
                className="form-control"
                id="inputPassword"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
              {/* <button type="button" onClick={handlePasswordToggle}>
                {showPassword ? <ImEye /> : <PiEyeClosedBold />}
              </button> */}
            </div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  required
                />
                Remember Me
              </label>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            <div className="form-group">
              <button className="btn-auth">Login</button>
            </div>
            <div className="auth-footer">
              <a className="forgot-password" href="/forgotpassword">Forgot Password?</a>
              <p className="sign-up-prompt">
                Don't have an account? <a href="/signup">Sign up</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;


