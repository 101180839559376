
// import React, { useState } from 'react';
// import { Link, Navigate, useNavigate } from 'react-router-dom';
// import './Login.css'; 

// const ForgotPassword = () => {
//     const [email, setEmail] = useState('');
//     const [resetPasswordMode, setResetPasswordMode] = useState(false);
//     const [newPassword, setNewPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const navigate=useNavigate()

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         console.log('Password reset email sent to:', email);
//         setResetPasswordMode(true); 
//     };

//     const handleResetPassword = (e) => {
//         e.preventDefault();

//         if (newPassword !== confirmPassword) {
//             alert("Passwords do not match.");
//             return;
//         }
//         alert("password successfully created");
//         navigate("/login");
      
//     };

//     return (
        
//         <div className="container mt-5">
//         <div className="row justify-content-center">
//           <div className="col-md-8 col-lg-6 col-xl-4">
//             <div className="card shadow-sm border-dark">
//               <div className="card-body">
//                 <h2 className="card-title text-center mb-4">Forgot Password</h2>
                
//                 {!resetPasswordMode ? (
//                   <form onSubmit={handleSubmit}>
//                     <div className="mb-3">
//                       <label htmlFor="email" className="form-label">Email address</label>
//                       <input
//                         type="email"
//                         className="form-control"
//                         id="email"
//                         placeholder="Enter email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                       />
//                     </div>
//                     <button type="submit" className="btn btn-primary w-100">
//                       Reset Password
//                     </button>
//                   </form>
//                 ) : (
//                   <form onSubmit={handleResetPassword}>
//                     <div className="mb-3">
//                       <label htmlFor="newPassword" className="form-label">New Password</label>
//                       <input
//                         type="password"
//                         className="form-control"
//                         id="newPassword"
//                         placeholder="Enter new password"
//                         value={newPassword}
//                         onChange={(e) => setNewPassword(e.target.value)}
//                         required
//                       />
//                     </div>
//                     <div className="mb-3">
//                       <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
//                       <input
//                         type="password"
//                         className="form-control"
//                         id="confirmPassword"
//                         placeholder="Confirm new password"
//                         value={confirmPassword}
//                         onChange={(e) => setConfirmPassword(e.target.value)}
//                         required
//                       />
//                     </div>
//                     <button type="submit" className="btn btn-primary w-100">Save New Password</button>
//                   </form>
//                 )}
      
//                 <p className="text-center mt-3">
//                   <Link to="/" className="text-decoration-none">Back to Login</Link>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
// };

// export default ForgotPassword;




import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import './Login.css'; 
import { Container, Form, Button, Alert } from 'react-bootstrap';
// import ReCAPTCHA from "react-google-recaptcha";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(' https://demochatapi.emedha.in/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        alert('Password reset instructions sent to your email.');
        setError('');
        localStorage.removeItem('token')
       

      } else {
        const data = await response.json();
        setError(data.error || 'Failed to send password reset email. Please try again.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError('Failed to send password reset email. Please try again.');
      setSuccessMessage('');
    }
  };

  const [active,setActive]=useState(false)
  const onChangeCaptcha=()=>{
    setActive(!active)
  }

  return (
    <>
   
    <Container className="mt-5">
      <div className="forgot-password-form shadow p-4 rounded">
        <h2 className="text-center mb-4">Forgot Password</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <div className="text-center mt-3 mb-2 g-recaptcha">
          {/* <ReCAPTCHA
              sitekey="6LfB4CIqAAAAABTTABOif9nMWUiFuMfIp31_UU5I"
            // onChange={onChangeCaptcha}
            /> */}
          </div>

          <Button variant="primary" type="submit" className="w-100 mt-3">
            Send Reset Instructions
          </Button>
        </Form>
        <p className="text-center mt-3">
                  <Link to="/" className="text-decoration-none">Back to Login</Link>
                 </p>
      </div>
      <br></br>
    </Container>
    <br></br>
   
    </>

  );
};

export default ForgotPasswordForm;
