
import React, { useState } from 'react';
import '../Chat/ChatList.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoMdContact, IoMdPerson } from 'react-icons/io';
import { MdGroups2, MdFavorite } from 'react-icons/md';
import { FaSignOutAlt } from 'react-icons/fa';
import { Modal, Button, Form } from 'react-bootstrap';
import { GoPlus } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import A1 from '../Images/logo-w.png';
import * as XLSX from 'xlsx';

const ChatList = ({ onSelectChat, contacts, setContacts, groups, setGroups }) => {
  const [isContactsVisible, setIsContactsVisible] = useState(false);
  const [isGroupsVisible, setIsGroupsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newUserName, setNewUserName] = useState('');
  const [newUserContact, setNewUserContact] = useState('');
  const [userImage, setUserImage] = useState(null);
  const [bulkUpload, setBulkUpload] = useState(null);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [groupProfileImage, setGroupProfileImage] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [bulkGroupMembers, setBulkGroupMembers] = useState([]); // New state to store bulk upload members
  const navigate = useNavigate();

  const handleGroupModalClose = () => {
    setShowGroupModal(false);
    setNewGroupName('');
    setGroupProfileImage(null);
    setSelectedMembers([]);
    setBulkGroupMembers([]); // Clear the bulk members when closing the modal
  };

  const handleGroupModalShow = () => setShowGroupModal(true);

  const handleGroupImageChange = (e) => {
    const file = e.target.files[0];
    setGroupProfileImage(URL.createObjectURL(file));
  };

  const handleMemberToggle = (contact) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(contact)
        ? prevSelected.filter((c) => c !== contact)
        : [...prevSelected, contact]
    );
  };

  const handleCreateGroup = () => {
    if (newGroupName.trim() !== '') {
      const newGroupId = Date.now(); // Generate a unique ID for the new group

      setGroups([
        ...groups,
        {
          id: newGroupId,
          name: newGroupName,
          profileImage: groupProfileImage,
          members: [...selectedMembers.map((member) => member.id), ...bulkGroupMembers.map((member) => member.id)],
        },
      ]);

      handleGroupModalClose();
    }
  };

  const handleContactsClick = () => {
    setIsContactsVisible(!isContactsVisible);
    setIsGroupsVisible(false);
  };

  const handleGroupsClick = () => {
    setIsGroupsVisible(!isGroupsVisible);
    setIsContactsVisible(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setNewUserName('');
    setNewUserContact('');
    setUserImage(null);
    setBulkUpload(null);
  };

  const handleModalShow = () => setShowModal(true);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUserImage(URL.createObjectURL(file));
  };

  const handleBulkFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const headers = jsonData[0] || [];
        const rows = jsonData.slice(1) || [];

        const newUsers = rows.map((row, index) => {
          let rowObject = {};
          headers.forEach((header, i) => {
            rowObject[header.trim()] = row[i] || '';
          });

          return {
            id: Date.now() + index, // Unique ID based on current time and index
            name: rowObject['NAME'] || '',
            number: rowObject['NUMBER'] || '',
            profileImage: rowObject['PROFIL'] || '',
          };
        });
        console.log("Bulk-uploaded group members:", newUsers);

        setBulkGroupMembers(newUsers); // Save members to the group instead of global contacts
      };
      reader.readAsArrayBuffer(file);
    }
  };

  console.log(bulkGroupMembers)
  console.log()


  const handleAddContact = () => {
    if (newUserName.trim() !== '' && newUserContact.trim() !== '') {
      const ids = contacts.map(c => Number(c.id)).filter(id => !isNaN(id));
      const newId = ids.length > 0 ? Math.max(...ids) + 1 : 1;

      setContacts([
        ...contacts,
        { id: newId, name: newUserName, number: newUserContact, profileImage: userImage }
      ]);

      handleModalClose();
    }
  };

  const handleProfileClick = () => {
    // Handle profile click, e.g., navigate to the profile page
    console.log('My Profile clicked');
  };

  // const handleLogout = () => {
  //   localStorage.removeItem('userData');
  //   navigate('/');
  // };
  const handleLogout = () => {
    // Remove user data, token, and loginEmail from local storage
    localStorage.removeItem('userData');
    localStorage.removeItem('token'); // Ensure this matches the key you used for the JWT
    localStorage.removeItem('loginEmail'); // Remove loginEmail as well
    localStorage.removeItem('rememberMe');
    // Navigate to the homepage
    navigate('/');
  };
  
  const handleFavoritesClick = () => {
    // Handle favorites click, e.g., show favorite contacts
    console.log('Favorites clicked');
  };

  return (
    <div className="chat-list">
      <img src={A1} alt="image" style={{ height: '55px' }} />
      <hr />
      <div>
        <div className='sidebar-section' onClick={handleContactsClick}>
          <IoMdContact style={{ fontSize: '30px', marginRight: '8px' }} />
          <strong>Chats</strong>
          <GoPlus style={{ fontSize: '30px', marginLeft: '150px', cursor: 'pointer' }} onClick={handleModalShow} />
        </div>
      </div>
      {isContactsVisible && (
        <div className="chat-list-items">
          {contacts.map(chat => (
            <div
              key={chat.id}
              className="chat-item"
              onClick={() => onSelectChat(chat.id)}
              style={{ display: 'flex', alignItems: 'center', padding: '8px', borderBottom: '1px solid #ddd' }}
            >
              {chat.profileImage ? (
                <img
                  src={chat.profileImage}
                  alt="Profile"
                  style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                />
              ) : (
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '#ccc',
                    marginRight: '10px'
                  }}
                />
              )}
              {chat.name}
            </div>
          ))}
        </div>
      )}
      <div className='mt-3'>
        <div className='sidebar-section' onClick={handleGroupsClick}>
          <MdGroups2 style={{ fontSize: '30px', marginRight: '8px' }} />
          <strong>Group Chats</strong>
          <GoPlus style={{ fontSize: '30px', cursor: 'pointer', marginLeft: '100px' }} onClick={handleGroupModalShow} />
        </div>
        {isGroupsVisible && (
          <div className="chat-list-items">
            {groups.map(group => (
              <div
                key={group.id}
                className="chat-item"
                onClick={() => onSelectChat(group.id)}
              >
                {group.profileImage ? (
                  <img
                    src={group.profileImage}
                    alt="Group Profile"
                    style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                  />
                ) : (
                  <div
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor: '#ccc',
                      marginRight: '10px'
                    }}
                  />
                )}
                {group.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="sidebar-section" onClick={handleProfileClick}>
        <IoMdPerson style={{ fontSize: '30px', marginRight: '8px' }} />
        <strong>My Profile</strong>
      </div>
      <div className="sidebar-section" onClick={handleFavoritesClick}>
        <MdFavorite style={{ fontSize: '30px', marginRight: '8px' }} />
        <strong>Favorites</strong>
      </div>
      <div className="sidebar-section" onClick={handleLogout}>
        <FaSignOutAlt style={{ fontSize: '30px', marginRight: '8px' }} />
        <strong>Logout</strong>
      </div>

      {/* Modal for creating a new group */}
      <Modal show={showGroupModal} onHide={handleGroupModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="newGroupName">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter group name"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="groupProfileImage">
              <Form.Label>Group Profile Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleGroupImageChange}
              />
            </Form.Group>
            <Form.Group controlId="bulkUpload">
              <Form.Label>Bulk Upload (CSV or Excel)</Form.Label>
              <Form.Control
                type="file"
                accept=".csv, .xlsx"
                onChange={handleBulkFileChange}
              />
            </Form.Group>
            {bulkGroupMembers.length > 0 && (
              <div>
                <h5>Bulk-Uploaded Members</h5>
                <ul>
                  {bulkGroupMembers.map((member) => (
                    <li key={member.id}>
                      {member.name} - {member.number}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <Form.Group controlId="memberSelection">
              <Form.Label>Select Members</Form.Label>
              {contacts.map(contact => (
                <Form.Check
                  key={contact.id}
                  type="checkbox"
                  id={`member-${contact.id}`}
                  label={contact.name}
                  checked={selectedMembers.includes(contact)}
                  onChange={() => handleMemberToggle(contact)}
                />
              ))}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary btn-sm" onClick={handleCreateGroup}>
            Create Group
          </Button>
        </Modal.Footer>
      </Modal>





      {/* Modal for adding new user */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="newUserName">
              <Form.Label>New User Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter new user name"
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="newUserContact">
              <Form.Label>Contact</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter contact number"
                value={newUserContact}
                onChange={(e) => setNewUserContact(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="userImage">
              <Form.Label>Profile Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Form.Group>
            <Form.Group controlId="bulkUpload">
              <Form.Label>Bulk Upload (CSV or Excel)</Form.Label>
              <Form.Control
                type="file"
                accept=".csv, .xlsx"
                onChange={handleBulkFileChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary btn-sm" onClick={handleAddContact}>
            Add Contact
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChatList;
