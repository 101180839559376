import React, { useState } from 'react';
import ChatList from './Chatlist';
import ChatWindow from './ChatWindow';


const ChatPanel = () => {
  const [contacts, setContacts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);

  const handleSelectChat = (chatId) => {
    const chat = contacts.find(c => c.id === chatId) || groups.find(g => g.id === chatId);
    setSelectedChat(chat); // Pass the entire chat object
  };

  return (
    <div className="chat-panel">
      <ChatList
        onSelectChat={handleSelectChat}
        contacts={contacts}
        groups={groups}
        setContacts={setContacts}
        setGroups={setGroups}
      />
      {selectedChat && <ChatWindow chat={selectedChat} />}
    </div>
  );
};

export default ChatPanel;
