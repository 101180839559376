import React, { useState, useEffect } from 'react';
import { IoSend } from "react-icons/io5";
import { MdOutlineAttachFile } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { TbDotsVertical } from "react-icons/tb";
import '../Chat/ChatList.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const ChatWindow = ({ chat }) => {
    const { id: chatId, name: chatName, profileImage } = chat;
    const [inputValue, setInputValue] = useState('');
    const [messages, setMessages] = useState([]);
    const [currentChatMessages, setCurrentChatMessages] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    
    
    useEffect(() => {
        const fetchMessages = async () => {            
            const fetchedMessages = []; 
            setMessages(fetchedMessages);
        };

        fetchMessages();
    }, [chatId]);

    useEffect(() => {
        setCurrentChatMessages(messages);
    }, [messages]);

    const handleSendMessage = () => {
        if (inputValue.trim() === '' && !selectedFile) return;

        const newMessage = {
            text: inputValue.trim(),
            from: 'You',
            file: selectedFile,
            timestamp: new Date().toLocaleTimeString()
        };

        const updatedMessages = [...currentChatMessages, newMessage];
        setMessages(updatedMessages);
        setCurrentChatMessages(updatedMessages);
        setInputValue('');
        setSelectedFile(null);
    };

    const handleShareLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
                // You might want to add the location message here
            },
            (error) => {
                console.error('Error fetching location', error);
            }
        );
    };

    const handleChooseFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setInputValue(file.name);
        }
    };

    console.log(messages)
    return (
        <div className="chat-window">
            <div className="chat-header">
                {profileImage ? (
                    <img src={profileImage} alt="Profile" className="chat-header-image" />
                ) : (
                    <div className="chat-header-placeholder" />
                )}
                <h4>{chatName}</h4>
            </div>
            <div className="messages">
                {currentChatMessages.map((message, index) => (
                    <div key={index} className={`message ${message.from === 'You' ? 'sent' : 'received'}`}>
                        <span>{message.from}: {message.text}</span>
                        {message.file && (
                            <div>
                                <a href={URL.createObjectURL(message.file)} download={message.file.name}>
                                    {message.file.name}
                                </a>
                            </div>
                        )}
                        <TbDotsVertical />
                        <div className='text-end'>
                            {message.timestamp}
                        </div>
                    </div>
                ))}
            </div>
            <div className="message-input">
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Type a message"
                />
                <button onClick={handleShareLocation} className="chat-panel-action-btn btn-sm" variant="secondary">
                    <CiLocationOn />
                </button>
                <button className="chat-panel-action-btn btn-sm" variant="secondary">
                    <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                        <MdOutlineAttachFile />
                    </label>
                    <input
                        id="file-input"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleChooseFile}
                    />
                </button>
                <button onClick={handleSendMessage}>
                    <IoSend />
                </button>
            </div>
        </div>
    );
};

export default ChatWindow;
