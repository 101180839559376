import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChatPanel from './Components/Chat/Chatpanel';
import Login from './Components/Auth/Login';
import Signup from './Components/Auth/Signup';
import ForgotPassword from './Components/Auth/ForgotPassword';
import OtpVerificationForm from './Components/Auth/OtpForm/Otp';
import PasswordResetForm from './Components/Auth/PasswordResetFrom/PasswordResetFrom';
import ProtectedRoute from './Components/Auth/ProtectedRoute/ProtectedRoute';



function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/otp-form" element={<OtpVerificationForm />} />
          <Route path="/reset-password" element={<PasswordResetForm />} />
          <Route path="/reset-password/:token" element={<PasswordResetForm />} />
        <Route path="/chatpanel" element={<ProtectedRoute element={<ChatPanel/>}/> }/>
      </Routes>
    </Router>
  );
}

export default App;
