import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const OtpVerificationForm = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const Navigate=useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(' https://demochatapi.emedha.in/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, otp })
      });

      if (response.ok) {
        setSuccessMessage('OTP verification successful. You can now proceed to login.');
        setError('');
        Navigate('/')
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to verify OTP. Please try again.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Failed to verify OTP. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <>
   
    <Container className="mt-5">
      <div className="otp-form">
        <h2>Email Verification</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="otp">
            <Form.Label>OTP</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
          </Form.Group>

          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          <Button variant="primary" type="submit">
            Verify
          </Button>
        </Form>
      </div>
      <br></br>
    </Container>
   
    </>
  );
};

export default OtpVerificationForm;
